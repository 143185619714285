//#region Enumerators

export enum StringCompare {
    CaseInsensitive = 0,
    CaseSensitive = 1
}

//#endregion

//#region Constants

const LocaleCompareSensitivity = "base" as const;

//#endregion

//#region Types

export type StringEquals = string | null;

//#endregion

//#region Function Exports

function Equals(first: StringEquals, second: StringEquals, stringCompare: StringCompare): boolean {
    const firstNoNull = first ?? "";
    const secondNoNull = second ?? "";

    if (stringCompare === StringCompare.CaseInsensitive) {
        return firstNoNull.localeCompare(secondNoNull, undefined, { sensitivity: LocaleCompareSensitivity }) === 0;
    }

    return firstNoNull === secondNoNull;
}

function Format(format: string, ...args: string[]) {
    for (let index = 0; index < args.length; index++) {
        format = format.replace(`{${index}}`, args[index]);
    }
    return format;
}

function IsNumeric(value: string) {
    return isNaN(Number(value));
}

export default {
    Equals,
    Format,
    IsNumeric
};

//#endregion