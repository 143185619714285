//#region Constant Exports

const AjaxName = "x-requested-with" as const;
const AjaxValue = "XMLHttpRequest" as const;

export default {
    AjaxName,
    AjaxValue
}

//#endregion