import { registerRoute, setCatchHandler } from "workbox-routing";
import { NetworkOnly } from "workbox-strategies";
import { RouteHandlerCallbackOptions } from "workbox-core";
import stringUtilities, { StringCompare, StringEquals } from "../utilities/stringUtilities";
import httpHeader from "../constants/httpHeader";

//#region Enumerators

// Event types
enum EventType {
    Activate = "activate",
    Install = "install"
}

//#endregion

//#region Constants

// Incrementing OFFLINE_VERSION will kick off the install event and force
// previously cached resources to be updated from the network
const OffilineVersion = 3 as const;
const CacheName = `offline-v${OffilineVersion}` as const;
const OfflineUrl = "/Offline" as const;
const NavigateRequestMode = "navigate" as const;
const ReloadRequestCache = "reload" as const;

// Items that should be cached for our offline file
const offlineCacheItems = [
    OfflineUrl,
    "/assets/apple-touch-icon.png",
    "/assets/favicon.ico",
    "https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600",
    "/assets/stylesheets/theme.min.css",
    "/assets/stylesheets/theme-dark.min.css",
    "https://use.typekit.net/lrm3oox.css",
    "https://p.typekit.net/p.css?s=1&k=lrm3oox&ht=tk&f=13407&a=12571363&app=typekit&e=css",
    "https://fonts.gstatic.com/s/firasans/v11/va9E4kDNxMZdWfMOD5Vvl4jL.woff2",
    "https://fonts.gstatic.com/s/firasans/v11/va9B4kDNxMZdWfMOD5VnSKzeRhf6.woff2",
    "https://use.typekit.net/af/2f0e6a/00000000000000003b9b12e6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3",
    "https://use.typekit.net/af/2f0e6a/00000000000000003b9b12e6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3",
    "https://use.typekit.net/af/2f0e6a/00000000000000003b9b12e6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3"
] as const;

// Supported Http Methods
const HttpMethods = ["DELETE", "GET", "POST", "PUT"] as const;

// Define self to override default and define as ServiceWorkerGlobalScope
declare const self: ServiceWorkerGlobalScope;

//#endregion

//#region Event Listeners

// Populate the cache with the offline page and any supporting 
// assets when the service worker is installed
self.addEventListener(EventType.Install, (event) => {
    event.waitUntil(
        caches.open(CacheName)
            .then(function (cache) {
                // Setting {cache: 'reload'} in the new request will ensure that the
                // response isn't fulfilled from the HTTP cache; i.e., it will be from
                // the network
                return cache.addAll(
                    offlineCacheItems.map(item => {
                        return new Request(item, { cache: ReloadRequestCache })
                    })
                );
            })
    );

    // Force the waiting service worker to become the active service worker
    self.skipWaiting();
});

// Delete old caches that we are no longer using
self.addEventListener(EventType.Activate, (event) => {
    event.waitUntil(
        caches.keys().then(function (cacheNames) {
            return Promise.all(
                cacheNames.filter(function (cacheName) {
                    return cacheName.toLowerCase() !== CacheName.toLowerCase();
                }).map(function (cacheName) {
                    return caches.delete(cacheName);
                })
            );
        })
    );
});

//#endregion

//#region Configure Handlers

// Create our network only strategy and set it as our default handler for all requests
const networkOnlyHandler = new NetworkOnly();
const matchEverything = () => true;

HttpMethods.forEach(method => {
    registerRoute(matchEverything, networkOnlyHandler, method);
});

// Create a catch handler that will be used when an errors happen with our default handler
// If our request was a "navigate" request then return our offline page otherwise see if our 
// request is in our cache
const catchHandlerAsync = async (options: RouteHandlerCallbackOptions): Promise<Response> => {
    // Determine if our request is ajax or not and passed on that and 
    // request mode determine our failure request
    const isAjaxRequest = isAjax(options.request);
    const failureRequest = (options.request.mode === NavigateRequestMode || isAjaxRequest) ? OfflineUrl : options.request;

    // Pull failure request out of the cache and if not ajax return
    const cacheMatch = await caches.match(failureRequest, {
        cacheName: CacheName,
    }) as Response;

    if (!isAjaxRequest) {
        return cacheMatch;
    }

    // Since it is ajax get the body of our request which will be our offline 
    // page and send that as a message to the calling page
    const offlineBody = await cacheMatch.text();
    await sendMessageToClientsAsync(offlineBody);

    return Response.error();
};

setCatchHandler(catchHandlerAsync);

//#endregion

//#region Helpers

function isAjax(request: Request): boolean {
    const requestedWith: StringEquals = request.headers.get(httpHeader.AjaxName);

    return stringUtilities.Equals(requestedWith, httpHeader.AjaxValue, StringCompare.CaseInsensitive);
}

async function sendMessageToClientsAsync(message: string) {
    const allClients = await self.clients.matchAll();

    allClients.forEach(client => client.postMessage(message));
}

//#endregion